
.popperRoot {
  z-index: 3;
  
  .popperContent {
    margin: 30px 0 0 -30px;
    border-radius: 10px!important;

    @media screen and (max-width: 400px) {
      width: 100%;
      margin: 30px 0 0 0;
    }
    
    .iconUtile {
      align-items: center;
      margin: 10px 0;
      max-height: 100%;
      overflow-y: auto;
      width: 380px;
      height: 450px;

      @media screen and (max-width: 400px) {
        width: 300px;
      }
      
    
      ul {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        margin: 0;
        padding: 0 10px;
        overflow: hidden;
    
        li {
          display: flex;
          flex-direction: column;
          width: 118px;
          margin: 0;
          text-align: center;
  
          a {
            text-decoration: none;
          }
        
          .button {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 120px;
            width: 99%;
            text-decoration: none;
            margin: auto;
  
            img {
              width: 30px !important;
              height: 30px !important;
            }
        
            > div {
              width: 38px !important;
              height: 38px !important;
        
              svg {
                width: 28px !important;
                height: 28px !important;
              }
            }
  
            p {
              display: block;
              color: #585858;
              width: 100%;
              font-size: 11px !important;
              text-decoration: none;
              text-align: center;
              justify-content: center;
              font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis !important;
              margin-block: 5px;
              white-space: normal;
              overflow: visible;
              text-overflow: clip;
              text-decoration: none;
            }  
          }
        }
      }
    }
  }
}

  